<template>
    <div>
        <PageTitle
                :heading="$t('settings.lang_createPrinter')"
                :subheading="$t('settings.lang_createPrinter')"
                :icon=icon
                show-previous-button
                url-previous='/settings/printerSettings/printerManager'
        ></PageTitle>

        <CreatePrinter></CreatePrinter>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle";
    import CreatePrinter from "../../../components/settings/printerSettings/printManager/CreatePrinter";

    export default {
        name: "PrintManagerCreatePrinter",

        components: {
            CreatePrinter,
            PageTitle
        },

        data() {
            return {
                icon: 'pe-7s-plane icon-gradient bg-tempting-azure'
            }
        }
    }
</script>